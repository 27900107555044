import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from '../../components';
import CalendarCustom from '../../components/Calendar/CalendarCustom';
// eslint-disable-next-line max-len
import { COURSE_BY_STUDENT_STATUS, DATE_YMD, DATE_YMD_ISO, MSG, SETTING_TOOL_CERTIFICATE_STATUS_MAPPER, SETTING_TOOL_CERTIFICATE_TYPE, STUDENT_STATUS, STUDENT_STATUS_STRING, SYSTEM_PATH, THEME_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import SendMessageScreen from './SendMessageScreen';
import {debounce} from 'lodash'

const StudentGroupScreen = observer(props => {
    const [courses, setCourses] = useState([]);
    const [showListCourse, setShowListCourse] = useState(false);
    const [courseName, setCourseName] = useState();
    const [courseId, setCourseId] = useState();
    const [keepDataSearch, setKeepDataSearch ] = useState({});

    const fetchRef = useRef(0);

    // other
    const navigate = useNavigate();

    // store
    const { 
        groupAdminStore: { 
            studentsOfCompany, pagingStudentOfCompany, disabledStudentIdx, studentsOfCompanyActive,
            getStudentsOfCompany, setAttrObservable, clean,
            getTicketByGroup, ticketByGroup,
            exportStudentCSV, updateStatusStudent
        },
        modalStore: { openAlert, openWarningModal },
        courseStore: { getCoursesByName }
    } = useStore();

    // state
    const { register, handleSubmit, formState: { isSubmitting, errors }, getValues, setValue, trigger } = useForm();
    const [ selectedStudent, setSelectedStudent ] = useState([]);
    const [ isSelectedAllCustom, setIsSelectedAllCustom ] = useState(false);

    const selectedRowIds = useMemo(() => {
        const obj = {};
        selectedStudent.forEach(item => {
            obj[item.id] = true;
        });
        return obj;
    }, [selectedStudent])

    // lifecycle
    useEffect(() => {
        clean();
        getStudentsOfCompany();
        getTicketByGroup();

        window.addEventListener('click', onFocus);

        return () => {
            window.removeEventListener('click', onFocus);
        }
    }, []);
    
    const onFocus = () => {
        setShowListCourse(false);
    }

    // function
    const onFetchData = async (tableData, unsetPaging) => {
        !unsetPaging && setAttrObservable('pagingStudentOfCompany', tableData, true, false);
        const searchParams = {
            ...getValues(),
            courseId,
            ...tableData
        };
        const { isCompleteRate, isTotalCourseApply, ...payload} = searchParams;
        if(!searchParams?.ticketId || searchParams?.ticketId == 0) {
            delete payload?.ticketId;
        }
        if(!isCompleteRate) {
            delete payload?.completeRate;
        }
        if(!isTotalCourseApply) {
            delete payload?.totalCourseApply;
        }
        if(!isCompleteRate && !isTotalCourseApply) {
            delete payload?.isBiggerCompleteRate;
        }
        setKeepDataSearch(payload);
        getStudentsOfCompany(payload);
    }

    const onSearchData = (data) => {
        onFetchData(data);
    }

    const onSelectedAllChange = (value) => {
        setSelectedStudent(oldUsers => {
            let data = oldUsers;
            toJS(studentsOfCompanyActive).forEach(userRow => {
                const index = data.findIndex(e => e.id === userRow.id);
                if(value) {
                    if(index < 0) data = [ ...data, userRow ];
                } else {
                    if(index > -1) data = [...data.slice(0, index), ...data.slice(index + 1)];
                }
            })

            return data;
        })
        setIsSelectedAllCustom(value);
    }

    const onSelectedChange = (selectedRows, rows) => {
        const selectedIds = Object.keys(selectedRows);
        if(selectedIds?.length > 0) {
            setSelectedStudent(oldUsers => {
                let data = oldUsers;
                toJS(studentsOfCompanyActive).forEach(userRow => {
                    const index = data.findIndex(e => e.id === userRow.id);
                    if(selectedIds.includes(String(userRow.id))) {
                        if(index < 0) {
                            data = [...data, userRow];
                        }
                    } else {
                        if(index > -1) {
                            data = [...data.slice(0, index), ...data.slice(index + 1)];
                        }
                    }
                })

                const dataIds = data.map(e => e.id);
                if(toJS(studentsOfCompanyActive)?.length > 0) {
                    setIsSelectedAllCustom(toJS(studentsOfCompanyActive).every(e => dataIds.includes(e.id)));
                } else {
                    setIsSelectedAllCustom(false);
                }

                return data;
            })
        } else {
            setSelectedStudent([]);
            setIsSelectedAllCustom(false);
        }
    }

    const onShowSendMessage = () => {
        if(selectedStudent?.length > 0) {
            window.$('#sendMessageScreenModal').modal('show');
        } else {
            openAlert('メッセージを送信する受講者を選択してください。');
        }
    }

    const onExportCSV = () => {
        if(selectedStudent?.length > 0) {
            exportStudentCSV({ userIds: selectedStudent.map(e => e.id), ...(keepDataSearch || {}) });
        } else {
            exportStudentCSV(keepDataSearch || {});
        }
    }

    const onShowConfirmChangeStatusStudent = (id, status) => {
        const title = status === STUDENT_STATUS.ACTIVE ? MSG['confirm.confirm_active_student_title'] 
            : MSG['confirm.confirm_deactive_student_title'];
        const content = status === STUDENT_STATUS.ACTIVE ? MSG['confirm.confirm_active_student_content'] 
            : MSG['confirm.confirm_deactive_student_content'];
        openWarningModal(content, () => onUpdateStatusStudent(id, status), title);
    }

    const onUpdateStatusStudent = async (id, status) => {
        const res = await updateStatusStudent(id, { status });
        if(res) {
            onFetchData();
        }
    }

    const clearSelectedStudent = () => {
        setSelectedStudent([]);
    }

    const onChangeCheckbox = (field, value, otherField) => {
        setValue('completeRate', '');
        setValue('totalCourseApply', '');
        if(value && !getValues('isBiggerCompleteRate')) {
            setValue('isBiggerCompleteRate', '0');
        }
        setValue(field, value, { shouldValidate: true });
        if(value && getValues(otherField) === true) {
            setValue(otherField, false, { shouldValidate: true });
        }
    }

    // search courses
    const debounceFetcherCourses = useMemo(() => {
        const loadOptions = (courseName) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            getCoursesByName({ name: courseName, status: 1, themeType: THEME_TYPE.NORMAL }).then(res => {
                if (fetchId && fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setCourses(res);
                setShowListCourse(res?.length > 0);
            })
        }

        return debounce(loadOptions, 800);
    }, [getCoursesByName]);

    const handleChangeCourse = (event) => {
        const courseName = event.target.value;
        debounceFetcherCourses(courseName);
        setCourseName(courseName);
        if (courseName === '') {
            setCourseId(undefined);
        }
    }

    const handleSelectCourse = (course) => {
        setCourseName(course?.name);
        setCourseId(course?.id);
        setShowListCourse(false);
    }

    function calculateDateAfterYear (date, years = 1, formatDate = DATE_YMD) {
        if(!date) return '';
        return moment(date).add(years, 'years').subtract(1, 'days').format(formatDate);
    }

    // student columns
    const studentColumns = [
        {
            Header: 'No.',
            disableSortBy: true,
            width: '3%',
            Cell: ({ initialState, row }) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        {
                            initialState?.initialTableState?.pageSize *
                            initialState?.initialTableState?.pageIndex +
                            row.index +
                            1
                        }
                    </div>
                )
            }
        },
        {
            Header: '受講者名',
            accessor: 'firstName',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.firstName} {original?.lastName}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.email}
                    </div>
                );
            },
            width: '12.5%'
        },
        {
            Header: '受講期間',
            accessor: 'coursePeriod',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            original?.coursePeriod ? 
                                // eslint-disable-next-line max-len
                                `${original.coursePeriod.replace(/-/g, '/')} ~ ${moment(original.coursePeriod).add(1, 'years').subtract(1, 'd').format(DATE_YMD)}` : ''
                        }
                    </div>
                );
            },
            width: '8%'
        },
        {
            Header: '受講コース',
            accessor: 'ticketName',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.ticketName}
                    </div>
                );
            },
            width: '9%'
        },
        {
            Header: '申請単位数',
            accessor: 'countCourseApplied',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.countCourseApplied}
                    </div>
                );
            },
            width: '7.5%'
        },
        {
            Header: '研修認定新規・更新',
            accessor: 'toolCertificateType',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        { original?.toolCertificate?.type && (original?.toolCertificate?.type == SETTING_TOOL_CERTIFICATE_TYPE.NEW ? '新規' : '更新')}
                    </div>
                );
            },
            width: '7%'
        },
        {
            Header: '研修認定期間',
            accessor: 'toolCertificateStartDate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.toolCertificate?.startDate ? 
                            // eslint-disable-next-line max-len
                            `${moment(original.toolCertificate.startDate).format(DATE_YMD)} ~ ${calculateDateAfterYear(original.toolCertificate.startDate, 
                                original?.toolCertificate?.type == SETTING_TOOL_CERTIFICATE_TYPE.NEW ? 4 : 3)}`
                            : ''
                        }
                    </div>
                );
            },
            width: '9%'
        },
        {
            Header: '認定判定',
            accessor: 'toolCertificateLastStatus',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[original?.toolCertificate?.lastStatus] && 
                            // <img src={SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[original.toolCertificate.lastStatus].IMG}
                            //     width={20}
                            // />
                            <span className='fw-bolder' 
                                style={{'color': SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[original.toolCertificate.lastStatus].TEXT_COLOR}}>
                                {SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[original.toolCertificate.lastStatus].LABEL}
                            </span>
                        }
                    </div>
                );
            },
            width: '6.5%'
        },
        ...(Array.from({ length: 4 }).map((_, index) => ({
            Header: `${index + 1}年目`,
            accessor: `toolCertificateValue${index}`,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            original?.toolCertificate?.value?.[index] && (
                                !original.toolCertificate.value[index].disabled ? (
                                    original.toolCertificate.value[index].totalCourseCompletion > 0 ?
                                        <Link 
                                            to={`/student-detail/${original.id}?status=${COURSE_BY_STUDENT_STATUS.USER_COURSE_COMPLETE.key}`}
                                        >
                                            {original.toolCertificate.value[index].totalCourseCompletion}
                                        </Link>
                                        : original.toolCertificate.value[index].totalCourseCompletion
                                ) : '-'
                            )
                        }
                        {
                            index === 3 && 
                            original?.toolCertificate?.type && 
                            original?.toolCertificate?.type == SETTING_TOOL_CERTIFICATE_TYPE.UPDATE && 
                            '-'
                        }
                    </div>
                );
            },
            width: '5%'
        }))),
        {
            Header: 'アクション',
            accessor: 'action',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div className='d-flex align-items-center justify-content-center'>
                        {/* {
                            original?.status === STUDENT_STATUS.ACTIVE ? 
                                <i role={'button'} className='icon-btn icon-btn-red fa-solid fa-ban' 
                                    onClick={() => onShowConfirmChangeStatusStudent(original?.id, STUDENT_STATUS.INACTIVE)}></i>
                                : original?.status === STUDENT_STATUS.INACTIVE ? 
                                    <i role={'button'} className='icon-btn icon-btn-green fa-solid fa-check'
                                        onClick={() => onShowConfirmChangeStatusStudent(original?.id, STUDENT_STATUS.ACTIVE)}></i>
                                    : <i className='icon-btn icon-btn-hidden fa-solid fa-ban'></i>
                        } */}
                        <img role={'button'} 
                            className='mg-l-10' src='/images/eye.svg' alt='' onClick={() => navigate(`/student-detail/${original.id}`)}/>
                    </div>
                );
            },
            width: '7.5%'
        }
    ]

    return (
        <div id='main' className='wrapper main-student-list student-group-screen'>
            <div className='bres-cus'>
                <div className='container container-student-group-list'>
                    <ul>
                        <li>
                            <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link>
                        </li>
                        <li className='dash'>\</li>
                        <li>受講者リスト</li>
                    </ul>
                </div>
            </div>
            <section className='top-student-list'>
                <div className='container container-student-group-list'>
                    <h1 className='title-primary'>受講者リスト</h1>
                    <div className='content-top-student'>
                        <form onSubmit={handleSubmit(onSearchData)}>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6 col-12'>
                                    <div className='item'>
                                        <label htmlFor='email'>メールアドレス</label>
                                        <input type='text' id='email' {...register('email')} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-6 col-12'>
                                    <div className='item'>
                                        <label htmlFor='ticketId'>受講コース</label>
                                        <select className='select-status-student' {...register('ticketId')} id='ticketId'>
                                            <option value={''}>全て</option>
                                            {
                                                ticketByGroup?.length > 0 &&
                                                ticketByGroup.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-6 col-12'>
                                    <div className='item'>
                                        <label htmlFor='studentName'>受講者名</label>
                                        <input type='text' id='studentName' {...register('studentName')}/>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-6 col-12'>
                                    <div className='item'>
                                        <label>申込み日</label>
                                        <CalendarCustom
                                            date={getValues('coursePeriod') || null}
                                            displayMode={'date'}
                                            onChange={date => {
                                                setValue('coursePeriod', date ? moment(date).format(DATE_YMD_ISO) : null, { shouldValidate: true });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6 col-12 gr-course-wrapper'>
                                    <div className='item'>
                                        <label htmlFor='courseName'>受講済講座名</label>
                                        <input
                                            value={courseName}
                                            onChange={handleChangeCourse}
                                            onFocus={handleChangeCourse}
                                            type='text' id='courseName'
                                            autoComplete='off'
                                        />
                                    </div>
                                    {showListCourse && <div className='item gr-course-view'>
                                        <label></label>
                                        <div className='gr-course-list'>
                                            {courses.map((item, i) => {
                                                return (
                                                    <p key={i} onClick={() => handleSelectCourse(item)}>{item.name}</p>
                                                );
                                            })}
                                        </div>
                                    </div>}
                                </div>
                                <div className='col-md-6 col-sm-6 col-12'>
                                    <div className='item'>
                                        <label htmlFor='status'>ステータス</label>
                                        <select className='select-status-student' {...register('status')} id='status'>
                                            <option value={''}>全て</option>
                                            <option value={1}>有効</option>
                                            <option value={0}>無効</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row item-search-group'>
                                <div className='item item-1 item-2'>
                                    <div className='item-1-1'>
                                        <input type={'checkbox'} id='受講プラン消化率' 
                                            {...register('isCompleteRate')}
                                            onChange={(e) => onChangeCheckbox('isCompleteRate', e.target.checked, 'isTotalCourseApply')}/>
                                        <label htmlFor='受講プラン消化率'>受講プラン消化率</label>
                                    </div>
                                    <input type={'number'} {...register('completeRate')} min={0} max={100} disabled={!getValues('isCompleteRate')}/>
                                </div>
                                <div className='item item-1 item-2'>
                                    <div className='item-1-1'>
                                        <input type={'checkbox'} id='申請単位数' 
                                            {...register('isTotalCourseApply')}
                                            onChange={(e) => onChangeCheckbox('isTotalCourseApply', e.target.checked, 'isCompleteRate')}/>
                                        <label htmlFor='申請単位数'>申請単位数</label>
                                    </div>
                                    <input type={'number'} min={0} {...register('totalCourseApply')} disabled={!getValues('isTotalCourseApply')}/>
                                </div>
                                <div className='item item-1'>
                                    <select className='select-level' {...register('isBiggerCompleteRate')} defaultValue={'0'} 
                                        disabled={!getValues('isTotalCourseApply') && !getValues('isCompleteRate')}>
                                        <option value={0}>以下</option>
                                        <option value={1}>以上</option>
                                    </select>
                                </div>
                            </div>
                            <div className='text-center'>
                                <input type='submit' value='検索' disabled={isSubmitting}/>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className='content-student-list'>
                <div className='container container-student-group-list'>
                    <div className='filter-list'>
                        <ul>
                            {
                                selectedStudent?.length > 0 &&
                                <li role={'button'} onClick={clearSelectedStudent}>
                                    <a className='mg-l-0 pd-l-0 delete-all-send'>
                                        <i className='fa-solid fa-square-minus'></i>
                                        <span>すべてのデータを削除する</span>
                                    </a>
                                </li>
                            }
                            <li role={'button'} onClick={onShowSendMessage}>
                                <a className='mg-l-0 pd-l-0'><img src='/images/icon12.svg' alt='' />メッセージ送信</a>
                            </li>
                            <li role={'button'} onClick={onExportCSV}>
                                <a className='filter-list-last'><img src='/images/icon13.svg' alt='' />受講記録の出力</a>
                            </li>
                        </ul>
                    </div>
                    <Table
                        columns={studentColumns}
                        data={studentsOfCompany || []}
                        enableServerSidePaging={true}
                        initialTableState={pagingStudentOfCompany}
                        onFetch={onFetchData}
                        enableSelectRow={true}
                        scrollHorizontalWidth={'1200px'}
                        onSelectedChange={onSelectedChange}
                        selectedRowIds={selectedRowIds}
                        rowKey={'id'}
                        disableSubRowSelect={true}
                        lstDisabledRowSelect={disabledStudentIdx}
                        showDisabledRowSelect={false}
                        useCustomSelectedAll={true}
                        isSelectedAllCustom={isSelectedAllCustom}
                        isSelectedAll={false}
                        onSelectedAllChange={onSelectedAllChange}
                        className='lst-student-table mg-t-35'
                    />
                </div>
            </section>
            <SendMessageScreen selectedStudent={selectedStudent} selectedRowIds={selectedRowIds} setSelectedStudent={setSelectedStudent}/>

            <section>
                <div className='container container-student-group-list text-error font-bold mg-t-10 text-14'>
                    <div>【認定判定について】</div>
                    <div>A：新規の場合は合計40単位以上、更新の場合は３年目で30単位以上となった時点で表示。</div>
                    <div>B：新規、更新ともに5点以上となった時点で表示。</div>
                    <div>C：新規、更新ともに5単位未満の時点で表示。</div>
                    <div>D：研修認定期間の最終日までにAにならなかった場合に表示。</div>
                </div>
            </section>
        </div>

    )
})

export default StudentGroupScreen;