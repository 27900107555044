/* eslint-disable no-undef */
const URL_CONFIG = {
    new_production: {
        api_url: process.env.REACT_APP_API_URL,
        port: 443,
        ssl: true,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL,
        timeUpdateStep: 29, // seconds
        timeAutoPauseStep: 900 // seconds
    },
    new_staging: {
        api_url: process.env.REACT_APP_API_URL,
        port: 80,
        ssl: false,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL,
        timeUpdateStep: 29, // seconds
        timeAutoPauseStep: 900 // seconds
    },
    dev: {
        api_url: process.env.REACT_APP_API_URL,
        port: 80,
        ssl: true,
        prefix_url: '',
        version: '1.0.0',
        socket_url: process.env.REACT_APP_SOCKET_URL,
        timeUpdateStep: 29, // seconds
        timeAutoPauseStep: 900 // seconds
    },
    local: {
        api_url: 'localhost:3000/api/v1/student',
        port: 80,
        ssl: false,
        prefix_url: '',
        version: '1.0.0',
        socket_url: 'localhost:3000',
        timeUpdateStep: 29, // seconds
        timeAutoPauseStep: 900 // seconds
    }
};

export default URL_CONFIG[process.env.REACT_APP_ENV_MODE];